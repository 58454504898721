import React from 'react';
import '../css.components/ProgressBar.css'; // Подключаем стили для полосы

const ProgressBar = ({ remainingEnergyPercentage }) => {
  // Число слотов (50 слотов)
  const totalSlots = 20; // Задаем фиксированное количество слотов
  const slots = Array.from({ length: totalSlots });

  // Определяем, сколько слотов заполняется на основе процента оставшейся энергии
  const filledSlots = Math.round((100 - remainingEnergyPercentage) / 100 * totalSlots);

  return (
    <div className="progress-bar">
      {slots.map((_, index) => (
        <div
          key={index}
          className={`progress-slot ${index < filledSlots ? 'filled' : ''}`}
        />
      ))}
    </div>
  );
};

export default ProgressBar;
