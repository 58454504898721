import React, { useEffect, useRef } from 'react';
import '../css/Background.css';

const Background = () => {
  const sectionRef = useRef(null);

  const renderBlocks = () => {
    const section = sectionRef.current;
    const fragment = document.createDocumentFragment();

    for (let i = 0; i < 300; i++) {
      const span = document.createElement('span');
      span.classList.add('sp');
      Object.assign(span.style, {
        position: 'relative',
        display: 'block',
        background: '#000000a4',
        width: 'calc(16vw)',
        height: 'calc(15vw)',
        zIndex: '2',
      });
      fragment.appendChild(span);
    }

    section.appendChild(fragment);
  };

  useEffect(() => {
    renderBlocks();
  }, []);

  return <section className="bg" ref={sectionRef}></section>;
};

export default Background;
