import React, { useState } from 'react';
import EWE from '../Universes/EWE/src/EWE';
import EcoGame from '../Universes/ECI/EcoGame';
import EatsApp from '../Universes/EWI/src/EatsApp';
import '../css/UniverseSwitcher.css';
import loadingImage from '../components/public/load_screen_univ.avif';

const UniverseSwitcher = () => {
  const [currentUniverse, setCurrentUniverse] = useState('EWI');
  const [nextUniverse, setNextUniverse] = useState('');
  const [isTabOpen, setIsTabOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const universeOrder = ['EWE', 'ECI', 'EWI'];

  const getNextUniverse = (direction) => {
    const currentIndex = universeOrder.indexOf(currentUniverse);
    if (direction === 'next') {
      return universeOrder[(currentIndex + 1) % universeOrder.length];
    } else if (direction === 'prev') {
      return universeOrder[(currentIndex - 1 + universeOrder.length) % universeOrder.length];
    }
  };

  const changeUniverse = (direction) => {
    setIsButtonDisabled(true);
    const btnClass = direction === 'next' ? 'right-move' : 'left-move';
    const btnElement = direction === 'next' ? document.querySelector('.npBtn:nth-child(2)') : document.querySelector('.npBtn:nth-child(1)');
    btnElement.classList.add(btnClass);

    const animationDuration = 1400;

    setTimeout(() => {
      setIsLoading(true);
      const next = getNextUniverse(direction);
      setNextUniverse(next);
      setTimeout(() => {
        setCurrentUniverse(next);
        setIsLoading(false);
        setIsButtonDisabled(false);
      }, 3000);
    }, animationDuration);
  };

  const renderUniverse = () => {
    switch (currentUniverse) {
      case 'EWE':
        return <EWE setIsTabOpen={setIsTabOpen} />;
      case 'ECI':
        return <EcoGame setIsTabOpen={setIsTabOpen} />;
      case 'EWI':
        return <EatsApp setIsTabOpen={setIsTabOpen} />;
      default:
        return <EWE setIsTabOpen={setIsTabOpen} />;
    }
  };

  const renderButtonContent = (universe) => {
    if (universe === 'ECI') {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="lock-icon"
        >
          <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
          <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
        </svg>
      );
    }
    return universe;
  };

  return (
    <div className="container">
      {!isTabOpen && !isLoading && (
        <div className="btn-container">
          <button
            className={`npBtn ${getNextUniverse('prev') === 'ECI' ? 'disabled' : ''}`}
            onClick={() => changeUniverse('prev')}
            disabled={isButtonDisabled || getNextUniverse('prev') === 'ECI'}
          >
            {renderButtonContent(getNextUniverse('prev'))}
          </button>
          <button
            className={`npBtn ${getNextUniverse('next') === 'ECI' ? 'disabled' : ''}`}
            onClick={() => changeUniverse('next')}
            disabled={isButtonDisabled || getNextUniverse('next') === 'ECI'}
          >
            {renderButtonContent(getNextUniverse('next'))}
          </button>
        </div>
      )}
      <main>
        {isLoading ? (
          <div className="loading-screen">
            <img src={loadingImage} alt="Loading" />
            <p>Переход во вселенную {nextUniverse}</p>
          </div>
        ) : (
          renderUniverse()
        )}
      </main>
    </div>
  );
};

export default UniverseSwitcher;
