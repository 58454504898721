class UniverseData {
  constructor() {
    this.localData = null;
    this.isSyncing = false;
    this.syncInterval = null;
    this.setupEventListeners();
  }

  setupEventListeners() {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        console.log('Страница скрыта, синхронизируем...');
        if (!this.isSyncing) {
          this.syncWithServer(true);
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('beforeunload', () => {
      if (!this.isSyncing) {
        this.syncWithServer(true);
      }
    });
  }

  getGameTime() {
    return Date.now();
  }

  async initialize(initialData) {
    try {
      console.log('Инициализация с данными с сервера:', initialData);

      if (!initialData.energy || !initialData.energyMax || !initialData.regenRate) {
        console.error('Отсутствуют необходимые данные:', initialData);
        throw new Error('Неполные данные с сервера');
      }

      this.localData = {
        energy: Number(initialData.energy),
        energyMax: Number(initialData.energyMax),
        damageLevel: Number(initialData.damageLevel || 1),
        energyLevel: Number(initialData.energyLevel || 1),
        regenLevel: Number(initialData.regenLevel || 1),
        regenRate: Number(initialData.regenRate || 1),
        totalClicks: Number(initialData.totalClicks || 0),
        lastClickTime: Number(initialData.lastClickTime) || this.getGameTime(),
        lastRegenTime: Number(initialData.lastRegenTime) || this.getGameTime(),
        telegram_id: initialData.telegram_id,
        eweData: initialData.eweData || {
          tokens: 0,
          farmedTokens: 0,
          isFarming: false,
          startTime: null,
          elapsedFarmingTime: 0
        }
      };

      console.log('Локальные данные после инициализации:', this.localData);
      this.startSync();
      return this.localData;
    } catch (error) {
      console.error('Ошибка инициализации:', error);
      throw error;
    }
  }

  startSync() {
    if (this.syncInterval) {
      clearInterval(this.syncInterval);
    }

    this.syncInterval = setInterval(() => {
      if (!this.isSyncing) {
        console.log('Периодическая синхронизация');
        this.syncWithServer();
      }
    }, 10000);
  }

  calculateCurrentEnergy() {
    const gameNow = this.getGameTime();
    const timeDiff = Math.floor((gameNow - this.localData.lastRegenTime) / 1000);

    if (timeDiff >= 1) {
      const regenAmount = timeDiff * this.localData.regenRate;
      this.localData.energy = Math.min(
        this.localData.energy + regenAmount,
        this.localData.energyMax
      );
      this.localData.lastRegenTime = gameNow;

      console.log('Регенерация:', {
        secondsPassed: timeDiff,
        regenPerSecond: this.localData.regenRate,
        totalRegen: regenAmount,
        newEnergy: this.localData.energy,
        maxEnergy: this.localData.energyMax
      });
    }

    return this.localData.energy;
  }

  handleClick(damageAmount) {
    if (!this.localData) {
      console.error('localData не инициализирован');
      return false;
    }

    const currentEnergy = this.calculateCurrentEnergy();
    if (currentEnergy < 1) return false;

    this.localData.energy = currentEnergy - 1;
    this.localData.totalClicks = (this.localData.totalClicks || 0) + Number(damageAmount);
    this.localData.lastClickTime = this.getGameTime();
    this.localData.lastRegenTime = this.getGameTime();

    console.log('Клик:', {
      added: damageAmount,
      totalClicks: this.localData.totalClicks,
      energy: this.localData.energy,
      maxEnergy: this.localData.energyMax
    });

    return true;
  }

  updateEweData(eweData) {
    if (!this.localData) {
      console.error('localData не инициализирован');
      return false;
    }

    console.log('Обновление EWE данных:', eweData);

    this.localData.eweData = {
      ...this.localData.eweData,
      ...eweData
    };

    this.syncWithServer();
    return true;
  }

  handleUpgrade(type, cost) {
    if (!this.localData) {
      console.error('localData не инициализирован');
      return false;
    }

    console.log('Попытка апгрейда:', {
      type,
      cost,
      availableClicks: this.localData.totalClicks
    });

    if (!this.localData.totalClicks || this.localData.totalClicks < cost) return false;

    this.localData.totalClicks = Number(this.localData.totalClicks) - Number(cost);

    switch(type) {
      case 'damage':
        this.localData.damageLevel = (Number(this.localData.damageLevel) || 0) + 1;
        console.log(`Апгрейд урона до уровня ${this.localData.damageLevel}`);
        break;
      case 'energy':
        this.localData.energyLevel = (Number(this.localData.energyLevel) || 0) + 1;
        this.localData.energyMax = (Number(this.localData.energyMax) || 0) + 500;
        console.log(`Апгрейд энергии до уровня ${this.localData.energyLevel}, максимум: ${this.localData.energyMax}`);
        break;
      case 'regen':
        if ((Number(this.localData.regenLevel) || 0) < 5) {
          this.localData.regenLevel = (Number(this.localData.regenLevel) || 0) + 1;
          this.localData.regenRate = (Number(this.localData.regenRate) || 0) + 1;
          console.log(`Апгрейд регенерации до уровня ${this.localData.regenLevel}, скорость: ${this.localData.regenRate}`);
        }
        break;
      default:
        console.error('Неизвестный тип апгрейда:', type);
        return false;
    }

    this.syncWithServer();
    return true;
  }

  async syncWithServer(isFinal = false) {
    if (!this.localData || this.isSyncing) return;

    this.isSyncing = true;
    console.log('Начало синхронизации:', {
      isFinal,
      currentState: { ...this.localData }
    });

    try {
      const updateData = {
        user_id: this.localData.telegram_id,
        updates: {
          totalClicks: this.localData.totalClicks,
          energy: this.localData.energy,
          energyMax: this.localData.energyMax,
          damageLevel: this.localData.damageLevel,
          energyLevel: this.localData.energyLevel,
          regenLevel: this.localData.regenLevel,
          regenRate: this.localData.regenRate,
          eweData: this.localData.eweData
        },
        game_time: this.getGameTime(),
        last_sync_time: this.localData.lastRegenTime
      };

      if (isFinal) {
        const blob = new Blob([JSON.stringify(updateData)], { 
          type: 'application/json' 
        });
        navigator.sendBeacon(`${process.env.REACT_APP_BACKEND_URL}/update`, blob);
      } else {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/update`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(updateData)
        });

        if (response.ok) {
          const serverData = await response.json();
          console.log('Получен ответ от сервера:', serverData);
          
          // Обновляем локальные данные из ответа сервера
          if (serverData.totalClicks) {
            this.localData.totalClicks = serverData.totalClicks;
          }
        }
      }
    } catch (error) {
      console.error('Ошибка синхронизации:', error);
    } finally {
      this.isSyncing = false;
    }
  }

  cleanup() {
    console.log('Очистка перед закрытием');
    if (this.syncInterval) {
      clearInterval(this.syncInterval);
    }
    if (!this.isSyncing) {
      this.syncWithServer(true);
    }
  }

  getCurrentData() {
    if (!this.localData) {
      console.error('localData не инициализирован');
      return null;
    }
    return {
      ...this.localData,
      energy: this.calculateCurrentEnergy()
    };
  }
}

export default new UniverseData();
