import React, { useEffect, useState } from 'react';

function UserLogo({ userId, botToken, defaultLogo }) {
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    async function fetchLogo() {
      try {
        const response = await fetch(`https://api.telegram.org/bot${botToken}/getUserProfilePhotos?user_id=${userId}`);
        const data = await response.json();

        if (data.ok && data.result && data.result.photos.length > 0) {
          const photo = data.result.photos[0][0]; // Получаем самое первое фото
          const fileId = photo.file_id;

          const fileResponse = await fetch(`https://api.telegram.org/bot${botToken}/getFile?file_id=${fileId}`);
          const fileData = await fileResponse.json();

          if (fileData.ok) {
            const filePath = fileData.result.file_path;
            setLogo(`https://api.telegram.org/file/bot${botToken}/${filePath}`);
          } else {
            console.error('Error retrieving file path:', fileData.description);
            setLogo(defaultLogo); // Устанавливаем дефолтное изображение
          }
        } else {
          console.log('No profile photo available');
          setLogo(defaultLogo); // Устанавливаем дефолтное изображение
        }
      } catch (error) {
        console.error('Error fetching user profile photo:', error);
        setLogo(defaultLogo); // Устанавливаем дефолтное изображение
      }
    }
    
    fetchLogo();
  }, [userId, botToken, defaultLogo]);

  return <img src={logo || defaultLogo} className="logo" alt="User Logo" />;
}

export default UserLogo;
