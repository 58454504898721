import React, { useState, useEffect } from 'react';
import Score from './comp/Score';
import './css/EWE.css';
import FarmButton from './comp/FarmButton';
import UniverseData from '../../EWI/src/UniverseData';
import Background from './comp/Background';
import CircularProgressBar from './comp/CircularProgressBar';

const DEBUG = true;

function Ewe() {
  const debugLog = (message, data) => {
    if (DEBUG) {
      console.log(`[EWE Debug] ${message}`, data);
    }
  };

  const [tokens, setTokens] = useState(() => UniverseData.localData?.eweData?.tokens || 0);
  const [farmedTokens, setFarmedTokens] = useState(() => UniverseData.localData?.eweData?.farmedTokens || 0);
  const [isFarming, setIsFarming] = useState(() => UniverseData.localData?.eweData?.isFarming || false);
  const [startTime, setStartTime] = useState(() =>
    UniverseData.localData?.eweData?.startTime ? new Date(UniverseData.localData.eweData.startTime) : null
  );

  const maxTokens = 0.128;
  const farmingDuration = 12 * 60 * 60;

  // Эффект для синхронизации токенов с UniverseData
  useEffect(() => {
    const interval = setInterval(() => {
      const currentTokens = UniverseData.localData?.eweData?.tokens || 0;
      if (currentTokens !== tokens) {
        debugLog('Updating tokens from UniverseData', {
          current: tokens,
          new: currentTokens,
          difference: currentTokens - tokens
        });
        setTokens(currentTokens);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [tokens]);

  useEffect(() => {
    debugLog('Component mounted, initial state:', {
      isFarming,
      startTime: startTime?.getTime(),
      tokens,
      farmedTokens
    });

    if (isFarming && startTime) {
      const now = new Date();
      const elapsedSeconds = (now - startTime) / 1000;
      debugLog('Checking farming status:', {
        elapsedSeconds,
        remainingHours: ((farmingDuration - elapsedSeconds) / 3600).toFixed(2)
      });

      if (elapsedSeconds >= farmingDuration) {
        debugLog('Farm completed while away');
        setFarmedTokens(maxTokens);
        setIsFarming(false);
        UniverseData.updateEweData({
          tokens,
          farmedTokens: maxTokens,
          isFarming: false,
          startTime: null
        });
      } else {
        debugLog('Farm in progress');
        const farmed = (elapsedSeconds / farmingDuration) * maxTokens;
        setFarmedTokens(farmed);
      }
    }
  }, []);

  useEffect(() => {
    let farmingInterval;
    if (isFarming && startTime) {
      debugLog('Starting farming interval');

      farmingInterval = setInterval(() => {
        const now = new Date();
        const elapsedSeconds = (now - startTime) / 1000;

        if (elapsedSeconds >= farmingDuration) {
          debugLog('Farming complete');
          setFarmedTokens(maxTokens);
          setIsFarming(false);
          clearInterval(farmingInterval);

          UniverseData.updateEweData({
            tokens,
            farmedTokens: maxTokens,
            isFarming: false,
            startTime: null
          });
        } else {
          const farmed = (elapsedSeconds / farmingDuration) * maxTokens;
          debugLog('Farming progress', {
            farmed,
            remainingHours: ((farmingDuration - elapsedSeconds) / 3600).toFixed(2)
          });
          setFarmedTokens(farmed);
        }
      }, 1000);
    }

    return () => {
      if (farmingInterval) {
        debugLog('Clearing farming interval');
        clearInterval(farmingInterval);
      }
    };
  }, [isFarming, startTime, tokens]);

  const startFarming = () => {
    if (!isFarming) {
      const now = new Date();
      debugLog('Starting farm', { time: now.toISOString() });

      UniverseData.updateEweData({
        tokens,
        farmedTokens: 0,
        isFarming: true,
        startTime: now.getTime()
      });

      setStartTime(now);
      setIsFarming(true);
      setFarmedTokens(0);
    }
  };

  const collectTokens = () => {
    if (farmedTokens >= maxTokens) {
      const newTokens = Number((tokens + farmedTokens).toFixed(3));
      debugLog('Collecting tokens', {
        previous: tokens,
        collected: farmedTokens,
        new: newTokens
      });

      UniverseData.updateEweData({
        tokens: newTokens,
        farmedTokens: 0,
        isFarming: false,
        startTime: null
      });

      setTokens(newTokens);
      setFarmedTokens(0);
      setIsFarming(false);
      setStartTime(null);
    }
  };

  const handleButtonClick = () => {
    if (!isFarming && farmedTokens >= maxTokens) {
      collectTokens();
    } else if (!isFarming) {
      startFarming();
    }
  };

  return (
    <div className="App1">
      <Background />
      <header className="header">
        <Score tokens={tokens} />
      </header>
      <div className="content">
        <CircularProgressBar value={farmedTokens} maxValue={maxTokens} />
        <FarmButton
          isFarming={isFarming}
          farmedTokens={farmedTokens}
          maxTokens={maxTokens}
          onClick={handleButtonClick}
        />
      </div>
    </div>
  );
}

export default Ewe;
