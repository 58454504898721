import React, { useState, useEffect } from 'react';
import '../css.components/Notifications.css';
import UniverseData from '../UniverseData';

const NotificationsList = ({ notifications, onClose, onClaim }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';
    if (window.Telegram?.WebApp?.MainButton) {
      window.Telegram.WebApp.MainButton.hide();
    }
    return () => {
      document.body.style.overflow = '';
      document.body.style.position = '';
      if (window.Telegram?.WebApp?.MainButton) {
        window.Telegram.WebApp.MainButton.show();
      }
    };
  }, []);

  return (
    <div className="notifications-fullscreen">
      <div className="notifications-header">
        <h2>Notifications</h2>
        <button className="back-button" onClick={onClose}>
          Back
        </button>
      </div>
      <div className="notifications-list">
        {notifications
          .filter(notif => !notif.claimed)
          .map(notif => (
            <div key={notif.referral_user_id} className="notification-item">
              <div className="notification-content">
                <div className="notification-icon-emoji">🎉</div>
                <div className="notification-details">
                  <p className="notification-message">{notif.message}</p>
                  <p className="notification-bonus">
                    +{notif.bonus_ewi?.toLocaleString()} $EWI
                    <br/>
                    +{notif.bonus_ewe?.toLocaleString()} $EWE
                  </p>
                </div>
                <button 
                  className="claim-button"
                  onClick={() => onClaim(notif.referral_user_id)}
                >
                  Claim Reward
                </button>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

const NotificationIcon = ({ userData, onNotificationsClaimed }) => {
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);

  const fetchNotifications = async () => {
    try {
      console.log('Fetching notifications for user:', userData.telegram_id);
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/notifications`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: userData.telegram_id
        })
      });
      if (response.ok) {
        const data = await response.json();
        console.log('Received notifications:', data);
        setNotifications(data.notifications);
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const handleClaimReward = async (referralUserId) => {
    try {
      console.log('Claiming reward for referral:', referralUserId);
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/claim_bonus`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: userData.telegram_id,
          referral_user_id: referralUserId,
        }),
      });
      if (response.ok) {
        const data = await response.json();
        console.log('Claim response:', data);
        
        const currentData = UniverseData.getCurrentData();
        
        // Правильно обновляем данные с учетом EWE
        UniverseData.localData = {
          ...currentData,
          totalClicks: data.totalClicks,
          eweData: {
            ...currentData.eweData,
            tokens: Number((currentData.eweData?.tokens || 0) + (data.bonus_ewe || 0))
          }
        };
        
        onNotificationsClaimed(data.bonus_ewi, data.bonus_ewe);
        setNotifications(prev => 
          prev.filter(n => n.referral_user_id !== referralUserId)
        );
        
        UniverseData.syncWithServer();
      }
    } catch (error) {
      console.error('Error claiming reward:', error);
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    setShowNotifications(true);
    fetchNotifications();
  };

  const unclaimedCount = notifications.filter(n => !n.claimed).length;

  return (
    <>
      {unclaimedCount > 0 && (
        <button 
          className="notification-icon-button"
          onClick={handleClick}
          onTouchStart={handleClick}
        >
          <span className="notification-badge">{unclaimedCount}</span>
          <span className="notification-emoji">📩</span>
        </button>
      )}
      {showNotifications && (
        <NotificationsList
          notifications={notifications}
          onClose={() => setShowNotifications(false)}
          onClaim={handleClaimReward}
        />
      )}
    </>
  );
};

export default NotificationIcon;
