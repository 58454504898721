import React, { useState } from 'react';
import '../css.components/UpgradeTab.css';
import BOT from '../../public/bot.gif';
import BOOST1 from '../../public/refresh.gif'; 
import BOOST2 from '../../public/boosttap.gif'; 
import DAMAGE_UPGRADE from '../../public/click.gif'; 
import ENERGY_UPGRADE from '../../public/Energy.gif'; 
import REGEN_UPGRADE from '../../public/regen.gif'; 

const UpgradeTab = ({
  totalClicks,
  damageUpgradeCost,
  energyUpgradeCost,
  regenUpgradeCost,
  damageLevel,
  energyLevel,
  regenLevel,
  handleDamageUpgrade,
  handleEnergyUpgrade,
  handleRegenUpgrade
}) => {
  const [buttonState, setButtonState] = useState({
    damage: '',
    energy: '',
    regen: ''
  });

  const handleUpgradeClick = (type, handler) => {
    setButtonState((prevState) => ({
      ...prevState,
      [type]: 'onclic'
    }));

    handler();

    setTimeout(() => {
      setButtonState((prevState) => ({
        ...prevState,
        [type]: 'validate'
      }));
      setTimeout(() => {
        setButtonState((prevState) => ({
          ...prevState,
          [type]: ''
        }));
      }, 1250);
    }, 2250);
  };

  return (
    <div className="upgrades">
      {/* Апгрейды */}
      <div className="upgrade-section">
        <h4 className="header_upg">Upgrades</h4>
        <button
          className={buttonState.damage}
          onClick={() => handleUpgradeClick('damage', handleDamageUpgrade)}
          disabled={totalClicks < damageUpgradeCost}
        >
          <div className="upgrade-icon">
            <img src={DAMAGE_UPGRADE} alt="Damage Upgrade Animation" width="50" height="50" />
          </div>
          <h4 className="upgrade-txt">Damage {damageLevel}</h4>
          <p>+1 damage per click</p>
          <p>Cost: {Math.floor(damageUpgradeCost)} $EWI</p>
        </button>

        <button
          className={buttonState.energy}
          onClick={() => handleUpgradeClick('energy', handleEnergyUpgrade)}
          disabled={totalClicks < energyUpgradeCost}
        >
          <div className="upgrade-icon">
            <img src={ENERGY_UPGRADE} alt="Energy Upgrade Animation" width="50" height="50" />
          </div>
          <h4 className="upgrade-txt">Energy {energyLevel}</h4>
          <p>+500 max energy</p>
          <p>Cost: {Math.floor(energyUpgradeCost)} $EWI</p>
        </button>

        {regenLevel !== undefined && regenLevel < 5 ? (
          <button
            className={buttonState.regen}
            onClick={() => handleUpgradeClick('regen', handleRegenUpgrade)}
            disabled={totalClicks < regenUpgradeCost}
          >
            <div className="upgrade-icon">
              <img src={REGEN_UPGRADE} alt="Regeneration Upgrade Animation" width="50" height="50" />
            </div>
            <h4 className="upgrade-txt">Regeneration {regenLevel}</h4>
            <p>+1 regeneration speed</p>
            <p>Cost: {Math.floor(regenUpgradeCost)} $EWI</p>
          </button>
        ) : (
          <div className="upgrade-placeholder">
            <h4 className="upgrade-txt">Max Regeneration Level Reached</h4>
          </div>
        )}
      </div>

      {/* Бот для автоматического фарма */}
      <div className="autofarm-section">
        <h4 className="header_upg">Auto-Farming Bot</h4>
        <button className="disabled-btn" disabled={true}>
          <div className="bot-icon">
            <img src={BOT} alt="Bot Animation" width="50" height="50" />
          </div>
          <p className="soon-text">SOON</p>
        </button>
      </div>

      {/* Бусты */}
      <div className="boost-section">
        <h4 className="header_upg">Boosts</h4>
        <button className="disabled-btn" disabled={true}>
          <div className="boost-icon">
            <img src={BOOST1} alt="Boost 1 Animation" width="50" height="50" />
          </div>
          <h4>MAX Energy</h4>
          <p>This boost fully restores your energy.</p>
          <span className="tooltip">Coming soon</span>
        </button>
        <button className="disabled-btn" disabled={true}>
          <div className="boost-icon">
            <img src={BOOST2} alt="Boost 2 Animation" width="50" height="50" />
          </div>
          <h4>Progressive TAP</h4>
          <p>This boost multiplies your taps by 5</p>
          <span className="tooltip">Coming soon</span>
        </button>
      </div>
    </div>
  );
};

export default UpgradeTab;
