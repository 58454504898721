import React, { useEffect, useRef } from 'react';
import '../css.components/bg.css';

const Background = () => {
  const sectionRef = useRef(null);

  const renderBlocks = () => {
    const section = sectionRef.current;
    const fragment = document.createDocumentFragment();

    for (let i = 0; i < 50; i++) {
      const span = document.createElement('span');
      span.classList.add('sp1');
      Object.assign(span.style, {
        position: 'relative',
        display: 'block',
        background: '#000000a4',
        width: 'calc(15vw)',
        height: 'calc(15vw)',
        zIndex: '2',
        transition: 'transform 0.1s ease', // добавим плавное преобразование
      });
      fragment.appendChild(span);
    }

    section.appendChild(fragment);
  };

  const handleDeviceOrientation = (event) => {
    const { beta, gamma } = event; // beta — наклон вперед/назад, gamma — наклон влево/вправо

    // Ограничиваем наклон по осям для нормализации значений
    const rotationX = Math.min(Math.max(beta, -45), 45); // Ограничим наклон от -45 до 45
    const rotationY = Math.min(Math.max(gamma, -45), 45);

    // Применяем наклон только к отдельным блокам
    const blocks = sectionRef.current.querySelectorAll('.sp1');
    blocks.forEach((block, index) => {
      const offsetX = (rotationY / 45) * 20 * (index % 2 === 0 ? 1 : -1); // Быстрее смещение по X с учетом индекса
      const offsetY = (rotationX / 45) * 20 * (index % 2 === 0 ? 1 : -1); // Быстрее смещение по Y с учетом индекса

      block.style.transform = `translate(${offsetX}px, ${offsetY}px)`;
    });
  };

  useEffect(() => {
    renderBlocks();
    if (window.DeviceOrientationEvent) {
      window.addEventListener('deviceorientation', handleDeviceOrientation);
    }

    return () => {
      if (window.DeviceOrientationEvent) {
        window.removeEventListener('deviceorientation', handleDeviceOrientation);
      }
    };
  }, []);

  return <section className="bg1" ref={sectionRef}></section>;
};

export default Background;
