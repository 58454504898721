import React, { useState } from 'react';
import PurblePairs from '../games/purble-game/PurblePairs';
import AppleCatcher from '../games/apple-game/AppleCatcher';
import '../css.components/BoostTab.css';
import bombs from '../../public/Bombs.jpg'; // Импорт изображения
import soon from '../../public/Soon.gif'; // Импорт GIF изображения

const games = [
  { name: 'BOMBS!', image: bombs, id: 'APPLE_CATCHER' },
  { name: 'Game 2', image: null, id: 'GAME_2' },
  { name: 'Game 3', image: null, id: 'GAME_3' },
  { name: 'Game 4', image: null, id: 'GAME_4' },
  { name: 'Game 5', image: null, id: 'GAME_5' },
  { name: 'Game 6', image: null, id: 'GAME_6' },
  { name: 'Game 7', image: null, id: 'GAME_7' },
  { name: 'Game 8', image: null, id: 'GAME_8' },
  { name: 'Game 9', image: null, id: 'GAME_9' },
  { name: 'Game 10', image: null, id: 'GAME_10' },
  { name: 'Game 11', image: null, id: 'GAME_11' },
  { name: 'Game 12', image: null, id: 'GAME_12' },
  { name: 'Game 13', image: null, id: 'GAME_13' },
  { name: 'Game 14', image: null, id: 'GAME_14' },
  { name: 'Game 15', image: null, id: 'GAME_15' },
  { name: 'Game 16', image: null, id: 'GAME_16' },
  { name: 'Game 17', image: null, id: 'GAME_17' },
  { name: 'Game 18', image: null, id: 'GAME_18' },
  { name: 'Game 19', image: null, id: 'GAME_19' },
  { name: 'Game 20', image: null, id: 'GAME_20' },
];


const BoostTab = () => {
  const [activeGame, setActiveGame] = useState(null);

  const handleVictory = () => {
    setActiveGame(null);
  };

  return (
    <div className="boost">
      {!activeGame ? (
        <div className="game-selection">
          <h3 className="hov">Select a Mini-Game</h3>
          <div className="game-cards">
            {games.map((game, index) => (
              <div key={index} className="game-card" onClick={() => setActiveGame(game.id)}>
                {game.image ? (
                  <div className="game-card-content">
                    <img src={game.image} alt={game.name} className="game-image" />
                    <p className="game-title">{game.name}</p>
                  </div>
                ) : (
                  <div className="game-card-content soon-content">
                    <img src={soon} alt="Coming Soon" className="soon-image" />
                    <p className="game-title">Soon</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="game-container">
          {activeGame === 'PURBLE_PAIRS' && <PurblePairs onVictory={handleVictory} />}
          {activeGame === 'APPLE_CATCHER' && <AppleCatcher onGameOver={handleVictory} />}
          {/* Добавь компоненты для остальных игр по мере необходимости */}
          {games.map((game, index) => (
            activeGame === game.id && <div key={index}>Game {index + 1} component</div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BoostTab;
