import React, { useState, useEffect } from 'react';

const ReferralShare = () => {
  const [referralLink, setReferralLink] = useState('');

  const handleShare = async () => {
    try {
      const userId = window.Telegram?.WebApp?.initDataUnsafe?.user?.id || 'test_user';
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get_referral_link`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user_id: userId })
      });
      
      const data = await response.json();
      setReferralLink(data.referral_link);
      
      // Использование метода как в Vue примере
      const shareText = encodeURIComponent("Join our awesome game!");
      const shareUrl = encodeURIComponent(data.referral_link);
      const tgLink = `https://t.me/share/url?text=${shareText}&url=${shareUrl}`;
      window.Telegram.WebApp.openTelegramLink(tgLink);
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  const handleCopy = () => {
    if (referralLink) {
      navigator.clipboard.writeText(referralLink)
        .then(() => showMessage('Referral link copied!'))
        .catch((error) => showMessage('Failed to copy: ' + error.message));
    }
  };

  const showMessage = (message) => {
    const messageElement = document.createElement('div');
    messageElement.textContent = message;
    messageElement.style.position = 'fixed';
    messageElement.style.top = '50%';
    messageElement.style.left = '50%';
    messageElement.style.transform = 'translate(-50%, -50%)';
    messageElement.style.backgroundColor = '#333';
    messageElement.style.color = '#fff';
    messageElement.style.padding = '15px 30px';
    messageElement.style.borderRadius = '5px';
    messageElement.style.zIndex = '1000';
    messageElement.style.textAlign = 'center';

    document.body.appendChild(messageElement);
    setTimeout(() => messageElement.remove(), 1000);
  };

  return (
    <div style={{ 
      padding: '10%',
      position: 'relative',
      zIndex: 999,
      height: '100vh',
      background: '#212121'
    }}>
      <h1 style={{ 
        textAlign: 'center', 
        color: 'white', 
        fontSize: '24px',
        fontWeight: 'bold',
        margin: '20px 0'
      }}>
        FRIEND REWARDS
      </h1>

      <div style={{ 
        background: 'rgba(128, 128, 128, 0.8)', 
        padding: '15px', 
        borderRadius: '5px', 
        marginBottom: '20px', 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <span>{referralLink || 'Your referral link will appear here'}</span>
        <button 
          onClick={handleCopy} 
          style={{ 
            backgroundColor: 'rgb(102, 204, 170)',
            width: '15vw',
            height: '50px',
            borderRadius: '15px',
            marginLeft: '10px',
            border: 'none',
            cursor: 'pointer'
          }}
        >
          Copy
        </button>
      </div>

      <div style={{ 
        background: 'rgba(128, 128, 128, 0.8)', 
        padding: '15px', 
        borderRadius: '5px', 
        marginBottom: '20px' 
      }}>
        <h2>You will receive:</h2>
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px 0' }}>
          <span>EWI Tokens:</span>
          <span>50,000</span>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px 0' }}>
          <span>EWE Tokens:</span>
          <span style={{ color: '#00f7ff' }}>1.792</span>
        </div>
      </div>

      <div style={{ 
        background: 'rgba(128, 128, 128, 0.8)', 
        padding: '15px', 
        borderRadius: '5px', 
        marginBottom: '20px' 
      }}>
        <h2>Your friend will receive:</h2>
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px 0' }}>
          <span>EWI Tokens:</span>
          <span>25,000</span>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px 0' }}>
          <span>EWE Tokens:</span>
          <span style={{ color: '#00f7ff' }}>0.896</span>
        </div>
      </div>

      <button 
        onClick={handleShare} 
        style={{ 
          backgroundColor: 'rgb(102, 204, 170)',
          width: '100%',
          height: '50px',
          borderRadius: '15px',
          border: 'none',
          color: 'white',
          fontSize: '16px',
          fontWeight: 'bold',
          cursor: 'pointer'
        }}
      >
        SHARE WITH FRIENDS
      </button>
    </div>
  );
};

export default ReferralShare;
