import React from 'react';
import '../css/CircularProgressBar.css';

const CircularProgressBar = ({ value, maxValue }) => {
  const percentage = Math.min((value / maxValue) * 100, 100);
  const blocks = Array.from({ length: 72 }, (_, i) => i); // Увеличено количество блоков до 72
  const radius = 45; // Радиус окружности для размещения блоков
  const blockSize = 4; // Размер блока, можно изменить по желанию
  const angleStep = 360 / 72; // Угол между блоками

  return (
    <div className="circular-progress-bar">
      <svg viewBox="0 0 100 100" className="progress-svg">
        {blocks.map((block, index) => {
          const angle = index * angleStep; // Угол для текущего блока
          const x = 50 + radius * Math.cos((angle - 90) * (Math.PI / 180));
          const y = 50 + radius * Math.sin((angle - 90) * (Math.PI / 180));

          return (
            <circle
              key={index}
              cx={x}
              cy={y}
              r={blockSize / 2} // Радиус круга блока
              className={`progress-block ${index < (percentage / 100) * 72 ? 'filled' : ''}`}
            />
          );
        })}
      </svg>
      <div className="progress-text">{Math.floor(percentage)}%</div>
    </div>
  );
};

export default CircularProgressBar;
