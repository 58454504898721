import React, { useState, useEffect, useRef, useCallback } from 'react';
import './EatsApp.css';
import ReferralShare from './components/ReferralShare';
import ProgressBar from './components/ProgressBar';
import logo from '../public/logo.jpg'
import 'react-circular-progressbar/dist/styles.css';
import UniverseData from './UniverseData';
import UpgradeTab from './components/UpgradeTab';
import BoostTab from './components/BoostTab';
import NotificationIcon from './components/Notifications';
import clickerImage from '../public/EWI.png';
import Ref from '../public/ref.png'
import Games from '../public/games.png'
import Upgrade from '../public/upgrade.png'
import Wallet from '../public/wallet.png'
import Birge from '../public/birge.png'
import Background from './components/bg';
import UserLogo from './components/UserLogo'
import WalletPage from './components/WalletPage'

const DamageIndicator = ({ x, y, damage }) => (
  <div className="damage-indicator" style={{ left: x, top: y }}>
    {damage}
  </div>
);

function EatsApp({ setIsTabOpen }) {
  const [gameState, setGameState] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(null);
  const [isImageDistorted, setIsImageDistorted] = useState(false);
  const [isTabOpenState, setIsTabOpenState] = useState(false);
  const [showButtons, setShowButtons] = useState(true);
  const [damageIndicators, setDamageIndicators] = useState([]);
  const activityTimeoutRef = useRef(null);
  const clickerRef = useRef(null);

  useEffect(() => {
    const initUser = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const userId = window.Telegram?.WebApp?.initDataUnsafe?.user?.id || 'test_user';

        if (userId === 'test_user') {
          const mockUserData = {
            totalClicks: 1000,
            damageLevel: 1,
            energy: 5000,
            energyMax: 10000,
            regenLevel: 1,
            energyLevel: 1,
          };
          await UniverseData.initialize(mockUserData);
          setGameState(mockUserData);
        } else {
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/init_user`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ user_id: userId }),
            }
          );

          if (!response.ok) throw new Error('Failed to initialize user');

          const userData = await response.json();
          await UniverseData.initialize(userData);
          setGameState(userData);
        }
      } catch (error) {
        console.error('Error initializing user:', error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    initUser();

    return () => {
      UniverseData.cleanup();
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (UniverseData.localData) {
        setGameState(UniverseData.getCurrentData());
      }
    }, 100);

    return () => clearInterval(interval);
  }, []);

  const userId = window.Telegram?.WebApp?.initDataUnsafe?.user?.id || 'test_user';
  const tapMessages = [
    "Tap more! Keep going!",
    "TAP TAP TAP!",
    "You're on fire!",
    "Fast fingers!",
    "Speedy clicker!",
    "Go, go, go!",
    "Click like crazy!",
    "Tap attack!",
    "Quick, quick!",
    "Lightning speed!",
    "Tap faster!",
    "Don't stop now!",
    "Keep tapping!",
    "The click master!",
    "You're unstoppable!",
    "Mega tap!",
    "Tap like a pro!",
    "Click frenzy!",
    "Power tap!",
    "Tap storm!",
    "Fastest clicker in town!",
  ];

  const [clickCount, setClickCount] = useState(0);
  const [lastClickTime, setLastClickTime] = useState(0);
  const [tapMessage, setTapMessage] = useState("");
  const [lastMessageTime, setLastMessageTime] = useState(0);

  const handleInteraction = useCallback((e) => {
    e.preventDefault();
    if (!gameState || gameState.energy < 1) return;

    const clicked = UniverseData.handleClick(gameState.damageLevel);
    if (!clicked) return;

    setIsImageDistorted(true);

    const currentTime = Date.now();
    const timeElapsed = currentTime - lastClickTime;

    if (timeElapsed < 500) {
      setClickCount((prevCount) => prevCount + 1);
    } else {
      setClickCount(1);
    }

    setLastClickTime(currentTime);

    if (clickCount >= 6 && timeElapsed <= 1000) {
      if (currentTime - lastMessageTime >= 3000) {
        const randomMessage = tapMessages[Math.floor(Math.random() * tapMessages.length)];
        setTapMessage(randomMessage);
        setLastMessageTime(currentTime);
      }
    } else {
      setTapMessage("");
    }

    const rect = clickerRef.current.getBoundingClientRect();
    const x = e.clientX || (e.touches && e.touches[0].clientX);
    const y = e.clientY || (e.touches && e.touches[0].clientY);

    const offsetX = (x - rect.left) - rect.width / 2;
    const offsetY = (y - rect.top) - rect.height / 2;
    const rotateX = (offsetY / rect.height) * 30;
    const rotateY = -(offsetX / rect.width) * 30;

    clickerRef.current.style.transform = `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;

    const ripple = document.createElement('div');
    ripple.className = 'ripple';
    ripple.style.left = `${x - rect.left}px`;
    ripple.style.top = `${y - rect.top}px`;

    clickerRef.current.appendChild(ripple);

    setTimeout(() => {
      ripple.remove();
    }, 800);

    const newIndicator = {
      id: Date.now() + Math.random(),
      x: x - rect.left,
      y: y - rect.top,
      damage: gameState.damageLevel,
    };

    setDamageIndicators((prev) => [...prev, newIndicator]);

    setTimeout(() => {
      setDamageIndicators((prev) => prev.filter((indicator) => indicator.id !== newIndicator.id));
    }, 1000);

    setGameState(UniverseData.getCurrentData());

    if (activityTimeoutRef.current) {
      clearTimeout(activityTimeoutRef.current);
    }

    activityTimeoutRef.current = setTimeout(() => {
      setIsImageDistorted(false);
      clickerRef.current.style.transform = 'rotateX(0deg) rotateY(0deg)';
    }, 100);
  }, [gameState, clickCount, lastClickTime, lastMessageTime]);

  useEffect(() => {
    if (tapMessage) {
      const timeout = setTimeout(() => {
        setTapMessage('');
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [tapMessage]);

  useEffect(() => {
    const clicker = clickerRef.current;
    if (clicker) {
      clicker.addEventListener('click', handleInteraction);
      clicker.addEventListener('touchstart', handleInteraction, { passive: false });

      return () => {
        clicker.removeEventListener('click', handleInteraction);
        clicker.removeEventListener('touchstart', handleInteraction);
      };
    }
  }, [handleInteraction]);


    const handleNotificationsClaimed = (bonusEwi, bonusEwe) => {
      if (!gameState) return;

      setGameState((prevState) => ({
        ...prevState,
        totalClicks: prevState.totalClicks + bonusEwi,
        eweData: {           ...prevState.eweData,
         tokens: (prevState.eweData.tokens || 0) + bonusEwe
       }
     }));
   };


  const handleTabOpen = (tab) => {
    setActiveTab(tab);
    setIsTabOpenState(true);
    setIsTabOpen?.(true);
    setShowButtons(false);
  };

  const handleBackButtonClick = () => {
    setActiveTab(null);
    setIsTabOpenState(false);
    setIsTabOpen?.(false);
    setShowButtons(true);
  };

  const handleDamageUpgrade = () => {
    const currentData = UniverseData.getCurrentData();
    const cost = 1000 * Math.pow(2, currentData.damageLevel - 1);

    if (currentData.totalClicks >= cost) {
      if (UniverseData.handleUpgrade('damage', cost)) {
        setGameState(UniverseData.getCurrentData());
      }
    }
  };

  const handleEnergyUpgrade = () => {
    const currentData = UniverseData.getCurrentData();
    const cost = 1000 * Math.pow(2, currentData.energyLevel - 1);

    if (currentData.totalClicks >= cost) {
      if (UniverseData.handleUpgrade('energy', cost)) {
        setGameState(UniverseData.getCurrentData());
      }
    }
  };

  const handleRegenUpgrade = () => {
    const currentData = UniverseData.getCurrentData();
    const cost = 50000 * Math.pow(2, currentData.regenLevel - 1);

    if (currentData.totalClicks >= cost && currentData.regenLevel < 5) {
      if (UniverseData.handleUpgrade('regen', cost)) {
        setGameState(UniverseData.getCurrentData());
      }
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!gameState) return <div>No game state available</div>;

  const remainingEnergyPercentage = (
    (gameState.energyMax - gameState.energy) / gameState.energyMax
  ) * 100;

  return (
    <div className="App">
      <Background />

      {/* Tabs */}
      {isTabOpenState && activeTab === 'UPGRADE' && (
        <div className="tab-container">
          <button className="back-button" onClick={handleBackButtonClick}>
            BACK
          </button>
          <UpgradeTab
            totalClicks={gameState.totalClicks}
            damageUpgradeCost={1000 * Math.pow(2, gameState.damageLevel - 1)}
            energyUpgradeCost={1000 * Math.pow(2, gameState.energyLevel - 1)}
            regenUpgradeCost={50000 * Math.pow(2, gameState.regenLevel - 1)}
            damageLevel={gameState.damageLevel}
            energyLevel={gameState.energyLevel}
            regenLevel={gameState.regenLevel}
            handleDamageUpgrade={handleDamageUpgrade}
            handleEnergyUpgrade={handleEnergyUpgrade}
            handleRegenUpgrade={handleRegenUpgrade}
          />
        </div>
      )}
      {isTabOpenState && activeTab === 'BOOST' && (
        <div className="tab-container">
          <button className="back-button" onClick={handleBackButtonClick}>
            BACK
          </button>
          <BoostTab onBack={handleBackButtonClick} />
        </div>
      )}
      {isTabOpenState && activeTab === 'SOON' && (
        <div className="tab-container">
          <button className="back-button" onClick={handleBackButtonClick}>
            BACK
          </button>
          <ReferralShare />
        </div>
      )}

      {/* Main Game Content */}
      {!isTabOpenState && (
        <header className="App-header">
          <NotificationIcon
            userData={gameState}
            onNotificationsClaimed={handleNotificationsClaimed}
          />
          <div className="up-cont">
            <div className="bs1">
              <div className="logo">
                <UserLogo userId={userId} botToken="YOUR_BOT_TOKEN" defaultLogo={logo} />
              </div>
            </div>
            <div className="balance-container">
              <img src={clickerImage} alt="Balance Icon" className="balance-icon" />
              <p>{Math.floor(gameState.totalClicks)}</p>
            </div>
            <div className="bs2">
              <div className='test'></div>
              <div className="energy-container">
                <div className="total-e">
                  <p>{Math.floor(gameState.energy)}</p>
                  <ProgressBar remainingEnergyPercentage={remainingEnergyPercentage} />
                </div>
              </div>
            </div>
          </div>
          <div className="wallet-birge">
            <div className="birge">
              <img src={Birge} className="birge-img" alt=""></img>
              LOCKED
            </div>
            <div className="wallet">
              <img src={Wallet} alt="Wallet" className="wallet-img" />
              LOCKED
            </div>
          </div>

          <div className="clicker-container" ref={clickerRef}>
            {tapMessage && (
              <div className="tap-message">
                {tapMessage}
              </div>
            )}
            <img
              src={clickerImage}
              alt="Clicker"
              className={`clicker-image ${isImageDistorted ? 'distorted' : ''}`}
            />
            {damageIndicators.map((indicator) => (
              <DamageIndicator
                key={indicator.id}
                x={indicator.x}
                y={indicator.y}
                damage={indicator.damage}
              />
            ))}
          </div>
        </header>
      )}

      {/* Bottom Navigation */}
      {showButtons && (
        <div className="tabs">
          <button
            className={activeTab === 'UPGRADE' ? 'active' : ''}
            onClick={() => handleTabOpen('UPGRADE')}
          >
            <img className="icon" src={Upgrade} alt="" />
          </button>
          <button
            className={activeTab === 'BOOST' ? 'active' : ''}
            onClick={() => handleTabOpen('BOOST')}
          >
            <img className="icon" src={Games} alt="" />
          </button>
          <button
            className={activeTab === 'SOON' ? 'active' : ''}
            onClick={() => handleTabOpen('SOON')}
          >
            <img className="icon" src={Ref} alt="" />
          </button>
        </div>
      )}
    </div>
  );
}

export default EatsApp;
